/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";

const BlogCard = ({
  blogImage,
  blogTitle,
  blogContent,
  blogLink,
  createdAt,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`card ${
        isHovered ? "hovered" : ""
      } tw-max-w-sm md:tw-w-[320px] tw-h-full md:tw-h-[400px] tw-rounded-lg tw-shadow-2xl 
      tw-dark:bg-gray-800 tw-dark:border-gray-700 tw-pb-3 `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img
        className="tw-rounded-t-lg tw-h-[50%] tw-w-full"
        src={blogImage}
        alt="blog-img"
      />
      <div className="tw-px-5 tw-mt-5">
        <h5
          className="tw-mb-2 tw-text-xl tw-capitalize tw-font-bold tw-tracking-tight tw-text-gray-900
           tw-dark:text-white tw-text-ellipsis tw-max-h-8 tw-w-full tw-overflow-hidden"
          style={{
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          {blogTitle}
        </h5>
        <p
          className="tw-font-normal tw-text-gray-700 tw-w-[full] tw-dark:text-gray-400 tw-max-h-16 
          tw-overflow-hidden tw-leading-tight tw-text-ellipsis"
          style={{
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
          }}
        >
          {blogContent}
        </p>
        <h1 className="tw-text-gray-900">Published Date : {createdAt}</h1>
      </div>
      <div className="tw-flex tw-absolute tw-bottom-4 tw-w-full tw-justify-center">
        <Link to={blogLink}>
          <button
            type="button"
            className="tw-bg-[#0891b2] tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-[#0d6379]"
          >
            Read more
          </button>
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
