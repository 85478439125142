import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header/index";
import Home from "./views/Home";
import About from "./views/About/index";
import Blogs from "./views/Blogs/index";
import Gallery from "./views/Gallery/index";
import Contact from "./views/Contact/index";
import BlogPost from "./components/BlogPost";
import Error from "./views/Error";
import Package from "./views/Package";
import Footer from "./components/Footer";
import PackageDetail from "./views/Package/PackageDetail";
import SubPackages from "./views/Package/SubPackages";

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blogs/:slug" element={<BlogPost />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/package/:id/:id" element={<PackageDetail />} />
          <Route path="/package/:id" element={<SubPackages />} />
          <Route path="/package" element={<Package />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
