import React from "react";

const Footer = () => {
  return (
    <footer className="tw-bg-[#d3ecf7] tw-hidden md:tw-py-8 dark:tw-bg-gray-900 md:tw-flex tw-justify-evenly tw-items-center">
      <div className="tw-text-center md:tw-text-left">
        <a href="/">
          <img src="/logo.svg" width={230} alt="logo" />
        </a>
      </div>
      <div className="tw-text-center md:tw-text-right">
        © 2022
        <a href="/" className="hover:tw-underline">
          Backcountry Trekking™
        </a>
        &nbsp; . &nbsp; All Rights Reserved.
      </div>
      <div className="tw-text-center md:tw-text-left">
        <ul className="tw-hidden sm:tw-flex sm:tw-flex-wrap tw-items-center tw-mt-5 sm:tw-mt-0 tw-mb-6 sm:tw-mb-0 ">
          <li>
            <a href="#about" className="tw-mr-4 hover:tw-underline md:tw-mr-6 ">
              About
            </a>
          </li>
          <li>
            <a href="/blogs" className="tw-mr-4 hover:tw-underline md:tw-mr-6">
              Blogs
            </a>
          </li>
          <li>
            <a
              href="/gallery"
              className="tw-mr-4 hover:tw-underline md:tw-mr-6 "
            >
              Gallery
            </a>
          </li>
          <li>
            <a href="/contact" className="hover:tw-underline">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
