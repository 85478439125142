import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import db from "../../firebaseConfig";
import { collection, addDoc } from "firebase/firestore";

const initialValues = {
  name: "",
  email: "",
  subject: "",
  message: "",
  contact: "",
  selectedPackage: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  contact: Yup.string()
    .matches(/^\d+$/, "Invalid contact number")
    .required("Contact is required"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
  selectedPackage: Yup.string(),
});

const ContactForm = ({ selectedPackage }) => {
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axios.post("https://formspree.io/f/xdorkpjv", {
        values,
        selectedPackage,
      });
      const formSubmissionsRef = collection(db, "formSubmissions");
      await addDoc(formSubmissionsRef, { ...values, selectedPackage });

      if (response.status === 200) {
        toast.success("Message sent successfully!");
        resetForm({ values: initialValues });
      } else {
        toast.error("Error sending message. Please try again.");
      }
    } catch (error) {
      toast.error("Error sending message. Please try again.");
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="tw-max-w-md tw-font-medium tw-text-base tw-bg-[#d3ecf7] tw-px-8 tw-py-5 tw-rounded-lg tw-drop-shadow-2xl">
          <h1 className="tw-text-2xl tw-font-bold">Send a message</h1>
          <div className="tw-mb-4">
            <label htmlFor="name">Name</label>
            <Field
              type="text"
              id="name"
              name="name"
              className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring focus:tw-ring-[#0891b2]"
            />
            <ErrorMessage
              name="name"
              component="div"
              className="tw-text-red-500"
            />
          </div>

          <div className="tw-mb-4">
            <label htmlFor="email">Email</label>
            <Field
              type="email"
              id="email"
              name="email"
              className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring focus:tw-ring-[#0891b2]"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="tw-text-red-500"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="contact">Contact Number</label>
            <Field
              type="number"
              id="contact"
              name="contact"
              className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring focus:tw-ring-[#0891b2]"
            />
            <ErrorMessage
              name="contact"
              component="div"
              className="tw-text-red-500"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="subject" className="block text-gray-700">
              Subject
            </label>
            <Field
              type="text"
              id="subject"
              name="subject"
              className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring focus:tw-ring-[#0891b2]"
            />
            <ErrorMessage
              name="subject"
              component="div"
              className="tw-text-red-500"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="message">Message</label>
            <Field
              as="textarea"
              id="message"
              name="message"
              rows="4"
              className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring focus:tw-ring-[#0891b2]"
            />
            <ErrorMessage
              name="message"
              component="div"
              className="tw-text-red-500"
            />
          </div>

          {selectedPackage && (
            <div>
              <label htmlFor="selectedPackage">Selected Package</label>
              <Field
                type="text"
                id="selectedPackage"
                name="selectedPackage"
                value={selectedPackage}
                readOnly
                className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring focus:tw-ring-[#0891b2]"
              />
            </div>
          )}

          <button
            type="submit"
            disabled={isSubmitting}
            className="tw-mt-4 tw-ml-[38%] tw-px-8 tw-py-3 tw-bg-[#0891b2] tw-rounded-md tw-text-white tw-text-base tw-font-bold hover:tw-bg-cyan-500"
          >
            {isSubmitting ? "Submitting..." : "Send"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
