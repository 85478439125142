import React from "react";
import { Link, useNavigate } from "react-router-dom";

const About = () => {
  const naviagte = useNavigate();

  const handleContactClick = (e) => {
    e.preventDefault();
    naviagte("/contact");
  };
  return (
    <div id="about" className="tw-bg-[#073549] tw-w-full md:tw-h-screen">
      <div className="tw-overflow-hidden tw-flex tw-flex-col tw-gap-2 md:tw-flex-row tw-w-full tw-px-8 tw-py-5 md:tw-px-20 md:tw-py-20 tw-justify-center tw-items-center">
        <div className="md:tw-w-[60%] tw-flex tw-flex-col tw-gap-3">
          <h1 className="title-color tw-text-5xl tw-font-semibold tw-tracking-wide lg:tw-text-6xl">
            About
          </h1>
          <p className="text-color sec-text ">
            I have been working as a tourist guide for more than 20 years. Even
            after all these years, I wake up excited, wondering about the beauty
            of Nepal. Throughout these years, I have met wonderful people with a
            warm heart. Most of these people always get back to me with their
            new life stories and experiences. I always love to be around people
            and this is my way of life.
          </p>
          <p className="text-color sec-text ">
            Travelling together with the right person can form a strong bond of
            camaraderie. If you seek a guide who is very much capable of being a
            fellow companion.
          </p>

          {/* <Link to="/contact">
            <button
              type="button"
              className="tw-button tw-px-8 tw-py-3 tw-bg-[#0891b2] tw-rounded-md tw-text-white tw-text-base tw-font-bold"
            >
              Contact Us
            </button>
          </Link> */}
          <Link to="/contact" onClick={handleContactClick}>
            <button
              type="button"
              className="tw-button tw-px-8 tw-py-3 tw-bg-[#0891b2] tw-rounded-md tw-text-white tw-text-base tw-font-bold"
            >
              Contact Us
            </button>
          </Link>
        </div>
        <div className="tw-w-full md:tw-flex-1 tw-py-4">
          <img className=" tw-w-full" src="trekking-adv.jpg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default About;
