import React, { useEffect } from "react";
import ContactForm from "../../components/ContactForm";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const Contact = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedPackage = searchParams.get("package");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div
      id="about"
      className="tw-bg-[#d3ecf7] tw-w-full tw-min-h-screen tw-min-w-full"
    >
      <ToastContainer />
      <div className="tw-py-10 tw-flex tw-flex-col md:tw-flex-row tw-items-center md:tw-px-10 tw-gap-5 tw-w-full">
        <div className="tw-text-center tw-px-3 md:tw-px-0 tw-mb-8 md:tw-w-1/4">
          <h1 className="lg:tw-px-0 lg:tw-my-4 tw-uppercase tw-text-4xl tw-font-semibold ">
            Contact Us
          </h1>
          <p className="tw-text-lg tw-font-medium ">
            Reach out for any other inquiries and questions using the contact
            form.
          </p>
        </div>
        <div className="md:tw-w-[50%] tw-justify-center tw-flex">
          <ContactForm selectedPackage={selectedPackage} />
        </div>
        <div className="tw-mt-8 md:tw-mt-0 md:tw-flex-1 tw-flex tw-flex-col tw-items-center">
          <div>
            <h1 className="tw-font-bold tw-text-xl md:tw-text-3xl">
              Contact Information
            </h1>
            <Link
              target="_blank"
              to="mailto:trekkingbackcountry@gmail.com"
              className="tw-cursor-pointer tw-text-lg tw-pt-2 tw-flex tw-items-center 
          hover:tw-animate-bounce tw-gap-2 tw-font-bold"
            >
              <i class="material-symbols-outlined tw-font-bold tw-text-2xl">
                mail
              </i>
              trekkingbackcountry@gmail.com
            </Link>
            <p className="tw-text-lg tw-flex tw-items-center tw-gap-2 tw-pt-1 tw-font-bold ">
              <i class="material-symbols-outlined tw-font-bold tw-text-2xl">
                phone_in_talk
              </i>
              Phone number: +0977 9861063094
            </p>
          </div>
          <div>
            <Link target="_blank" to="https://api.whatsapp.com/">
              <button
                type="button"
                className="button tw-px-4 tw-py-3 tw-mt-4 tw-bg-[#0891b2] tw-rounded-md tw-text-white
             tw-text-lg tw-font-bold hover:tw-bg-cyan-500 tw-flex tw-items-center tw-gap-3"
              >
                <img
                  src="/app-icon.png"
                  width={28}
                  className="tw-rounded-lg"
                  alt=""
                />
                +977 9856056057
              </button>
              <button
                type="button"
                className="button tw-px-4 tw-py-3 tw-mt-4 tw-bg-[#0891b2] tw-rounded-md tw-text-white
             tw-text-lg tw-font-bold hover:tw-bg-cyan-500 tw-flex tw-items-center tw-gap-3"
              >
                <img
                  src="/app-icon.png"
                  width={28}
                  className="tw-rounded-lg"
                  alt=""
                />
                +977 9861063094
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
