import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import client from "../../client";
import { extractContent } from "../contentHelpers";

const BlogPost = () => {
  const { slug } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const query = `*[_type == "blog" && slug.current == $slug]{
          _id,
          slug,
          title,
          content[]{
            _type == "block" => {
              _type,
              style,
              children[]{
                _type == "span" => {
                  _type,
                  marks,
                  text
                },
                _type == "image" => {
                  _type,
                  asset->{_ref}
                }
              }
            },
            _type == "image" => {
              _type,
              asset->{_ref}
            }
          },
          "imageUrl": poster.asset->url,
          "caption": poster.caption,
          createdAt,
        }`;
        const data = await client.fetch(query, { slug });
        if (data && data.length > 0) {
          setBlogPost(data[0]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [slug]);

  if (loading) {
    return (
      <div className="tw-bg-[#d3ecf7] tw-min-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center">
        <h2 className="tw-text-xl tw-font-bold tw-text-gray-800">Loading...</h2>
        <img
          src="/loader.svg"
          alt="loader"
          width={250}
          className="tw-mx-auto"
        />
      </div>
    );
  }

  if (!blogPost) {
    return (
      <div className="bg-[#d3ecf7] tw-min-h-screen tw-flex tw-items-center tw-justify-center">
        <h2 className="tw-text-xl tw-font-bold tw-text-gray-800">
          Blog post not found
        </h2>
      </div>
    );
  }

  return (
    <div className="tw-bg-blue-100 tw-min-h-screen">
      <div className="tw-max-w-screen-md tw-mx-auto tw-px-4 tw-py-10">
        <h1 className="tw-text-4xl tw-font-bold tw-mb-6">{blogPost.title}</h1>
        <p className="tw-text-gray-600 tw-mb-6">
          Published Date: {new Date(blogPost.createdAt).toLocaleDateString()}
        </p>
        {blogPost.imageUrl && (
          <img
            src={blogPost.imageUrl}
            alt={blogPost.title}
            className="tw-rounded-lg tw-mb-8"
          />
        )}
        {extractContent(blogPost.content)}
      </div>
    </div>
  );
};

export default BlogPost;
