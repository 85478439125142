import React from "react";
import DOMPurify from "dompurify";

const ItineraryComponent = ({ htmlContent }) => {
  const sanitizedHTML = DOMPurify.sanitize(htmlContent);

  return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
};

export default ItineraryComponent;
