/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import client from "../../client";
import BlogCard from "../../components/BlogCard";
import { extractContent } from "./../../components/contentHelpers";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const query = `*[_type == "blog"]{
          _id,
          slug,
          title,
          content[]{
            _type == "block" => {
              _type,
              style,
              children[]{
                _type == "span" => {
                  _type,
                  marks,
                  text
                },
                _type == "image" => {
                  _type,
                  asset->{_id, url}
                }
              }
            }
          },
          "imageUrl": poster.asset->url,
          "caption": poster.caption,
          createdAt,
        }`;
        const data = await client.fetch(query);
        setBlogs(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const text = blogs
      .flatMap((blog) => extractContent(blog.content))
      .join(" ");
    setDisplayText(text);
  }, [blogs]);

  return (
    <div
      className="tw-bg-[#d3ecf7] tw-min-h-screen tw-relative tw-w-full "
      id="blogs"
    >
      {loading ? (
        <div className="bg-[#d3ecf7] tw-min-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center">
          <h2 className="tw-text-xl tw-font-bold tw-text-gray-800">
            Loading...
          </h2>
          <img
            src="/loader.svg"
            alt="loader"
            width={250}
            className="tw-mx-auto"
          />
        </div>
      ) : (
        <div className="tw-flex tw-flex-wrap tw-px-[4%] tw-gap-[2%] tw-py-[2.5%] ">
          {blogs.map((blog) => (
            <div key={blog.slug.current} className="tw-mt-[2%]">
              <BlogCard
                blogImage={blog.imageUrl}
                blogTitle={blog.title}
                blogContent={extractContent(blog.content)}
                blogLink={`/blogs/${blog.slug.current}`}
                createdAt={new Date(blog.createdAt).toLocaleDateString()}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Blogs;
