import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import db from "../../../firebaseConfig";
import TravelCard from "../../../components/Travelcard";

const SubPackages = () => {
  const { id } = useParams();
  const [travelPackages, setTravelPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTravelPackages = async () => {
      try {
        const packageRef = collection(db, "travelpackages");
        const packageSnapshot = await getDocs(packageRef);

        const filteredPackages = [];

        for (const packageDoc of packageSnapshot.docs) {
          const packageData = { id: packageDoc.id, ...packageDoc.data() };
          const innerCollectionRef = collection(packageDoc.ref, "subPackages");
          const innerCollectionSnapshot = await getDocs(innerCollectionRef);
          const subPackages = innerCollectionSnapshot.docs.map((innerDoc) => ({
            id: innerDoc.id,
            ...innerDoc.data(),
          }));
          packageData.subPackages = subPackages;
          filteredPackages.push(packageData);
        }

        const filteredTravelPackages = filteredPackages.filter(
          (packageData) => packageData.title === id
        );

        setTravelPackages(filteredTravelPackages);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching travel packages:", error);
      }
    };

    fetchTravelPackages();
  }, [id]);

  const title = travelPackages.map((item) => item.title);

  return (
    <div
      id="package"
      className="tw-bg-[#d3ecf7] tw-relative tw-w-full tw-min-h-screen"
    >
      {loading ? (
        <div className="bg-[#d3ecf7] tw-min-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center">
          <h2 className="tw-text-xl tw-font-bold tw-text-gray-800">
            Loading...
          </h2>
          <img
            src="/loader.svg"
            alt="loader"
            width={250}
            className="tw-mx-auto"
          />
        </div>
      ) : (
        <>
          <h1 className="tw-text-center title-color tw-text-2xl sm:tw-text-4xl tw-font-semibold tw-tracking-wide xl:tw-text-5xl tw-uppercase">
            {title}
          </h1>
          <div className="tw-flex tw-flex-wrap tw-px-[4%] tw-gap-[2%] tw-pb-[2.5%] ">
            {travelPackages.map((travelPackage) =>
              travelPackage.subPackages.map((segment) => (
                <div key={segment.id} className="tw-mt-[2%]">
                  <TravelCard
                    packageImage={segment.image}
                    packageName={segment.title}
                    packageDetailLink={`/package/${travelPackage.title}/${segment.title}`}
                    packageLink={`/contact?package=${travelPackage.title} (${segment.title})`}
                    setSelectedPackage={setSelectedPackage}
                    packageDesc={segment.desc}
                    packagePrice={segment.price}
                    timeDuration={segment.days}
                  />
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SubPackages;
