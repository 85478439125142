import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./index.css";

const ImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="image-slider tw-shadow-md tw-shadow-[#aacede] tw-rounded-sm ">
      {images.length > 0 && (
        <div className="tw-flex tw-flex-col tw-gap-2 tw-pb-5 tw-px-10">
          <div className="tw-relative">
            <img
              src={images[currentImageIndex].image}
              alt={images[currentImageIndex].description}
              className="slider-image tw-w-full md:tw-h-[450px] "
              onClick={() => setIsModalOpen(true)}
            />

            <div className="navigation-buttons">
              <button className="left-button" onClick={handlePrevious}>
                &lt;
              </button>
              <button className="right-button" onClick={handleNext}>
                &gt;
              </button>
            </div>
            <div className="dots tw-py-4 tw-flex tw-justify-center">
              {images.map((image, index) => (
                <span
                  key={index}
                  className={index === currentImageIndex ? "dot active" : "dot"}
                  onClick={() => handleDotClick(index)}
                ></span>
              ))}
            </div>

            <Modal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              contentLabel="Enlarged Image"
              className="modal"
              overlayClassName="overlay"
            >
              <img
                src={images[currentImageIndex].image}
                alt={images[currentImageIndex].description}
                className="enlarged-image"
              />
            </Modal>
          </div>
          <div className="image-description tw-justify-center tw-flex tw-text-justify ">
            {images[currentImageIndex].description}
          </div>
          <div className="tw-flex tw-justify-center">
            <button
              className="tw-bg-[#1c7196] tw-text-white tw-text-sm tw-rounded-md tw-py-2 tw-px-2"
              onClick={() => setIsModalOpen(true)}
            >
              View Full Image
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageSlider;
