/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import db from "./../../firebaseConfig";
import TravelCard from "../../components/Travelcard";

const Package = () => {
  const [travelPackages, setTravelPackages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTravelPackages = async () => {
      try {
        const packageRef = collection(db, "travelpackages");
        const packageSnapshot = await getDocs(packageRef);
        const packages = packageSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTravelPackages(packages);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching travel packages:", error);
      }
    };

    fetchTravelPackages();
  }, []);

  return (
    <div
      id="package"
      className="tw-bg-[#d3ecf7] tw-relative tw-w-full tw-min-h-screen tw-pb-10"
    >
      {loading && (
        <div className="bg-[#d3ecf7] tw-min-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center">
          <h2 className="tw-text-xl tw-font-bold tw-text-gray-800">
            Loading...
          </h2>
          <img
            src="/loader.svg"
            alt="loader"
            width={250}
            className="tw-mx-auto"
          />
        </div>
      )}
      <h1 className="tw-text-center title-color tw-text-2xl sm:tw-text-4xl tw-font-semibold tw-tracking-wide xl:tw-text-5xl tw-uppercase ">
        Our Packages
      </h1>
      <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-flex-wrap tw-px-[4%] tw-gap-5 tw-pb-[2.5%] ">
        {travelPackages.map((travelPackage, index) => {
          return (
            <div key={index} className="tw-mt-[2%]">
              <TravelCard
                packageImage={travelPackage.image}
                packageName={travelPackage.title}
                packageDetailLink={`/package/${travelPackage.title}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Package;
