import React, { useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";

const TravelCard = ({
  packageImage,
  packageName,
  packageDesc,
  packagePrice,
  timeDuration,
  packageDetailLink,
  packageLink,
  setSelectedPackage,
  pickUpPoint,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleBookNowClick = () => {
    setSelectedPackage(packageName);
  };

  return (
    <div
      className={`card ${isHovered ? "hovered " : ""} 
      tw-max-w-sm tw-w-full md:tw-w-[320px] tw-h-full tw-pb-5 tw-rounded-lg 
      tw-shadow-xl tw-flex tw-flex-col tw-gap-5 `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img
        className="tw-rounded-t-lg tw-w-full md:tw-w-[350px] tw-h-[25vh]"
        src={packageImage}
        alt="blog-img"
      />
      <div className="tw-px-5 tw-h-fit">
        {packageName && (
          <div
            className="tw-text-2xl tw-font-bold tw-tracking-tight tw-text-gray-900 
          tw-dark:text-white tw-text-ellipsis tw-overflow-hidden tw-max-h-8"
          >
            {packageName}
          </div>
        )}
        {timeDuration && (
          <div className="tw-text-lg tw-font-bold tw-tracking-tight tw-text-gray-900 tw-dark:text-white">
            Duration : {timeDuration} Days
          </div>
        )}
        {pickUpPoint && (
          <div className="tw-text-lg tw-font-bold tw-tracking-tight tw-text-gray-900 tw-dark:text-white">
            Pick Up Point : {pickUpPoint}
          </div>
        )}
        {packageDesc && (
          <p
            className="tw-font-normal tw-text-gray-700 tw-w-[full] tw-dark:text-gray-400 tw-max-h-10 tw-overflow-hidden tw-leading-tight tw-text-ellipsis"
            style={{
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {packageDesc}
          </p>
        )}
      </div>
      <div className="tw-flex tw-justify-around ">
        <Link to={packageDetailLink}>
          <button className="tw-bg-[#0891b2] tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-[#0d6379]">
            View Details
          </button>
        </Link>
        {packageName && packageLink && (
          <Link to={packageLink} onClick={handleBookNowClick}>
            <button className="tw-bg-[#0891b2] tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-[#0d6379]">
              Book Now
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default TravelCard;
