import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import About from "../About";

const Home = () => {
  return (
    <>
      <main
        id="home"
        className="tw-bg-[#d3ecf7] tw-relative tw-w-full tw-h-[200vh]"
      >
        <section className="tw-sticky lg:tw-top-0 tw-z-0 tw-mx-auto tw-flex max-md:tw-flex-col max-md:tw-justify-evenly tw-h-[100vh] tw-w-full tw-max-w-[1350px] tw-items-center lg:tw-justify-between ">
          <div className="tw-space-y-8 max-md:tw-px-4  max-md:tw-pt-10">
            <div className="bird-container bird-container--one">
              <div className="bird bird--one"></div>
            </div>
            <div className="bird-container bird-container--three">
              <div className="bird bird--three"></div>
            </div>
            <div className="bird-container bird-container--four">
              <div className="bird bird--four"></div>
            </div>
            <h1 className="max-md:tw-text-4xl md:tw-space-y-1 tw-font-semibold tw-tracking-wide tw-text-6xl">
              <span className="tw-text-transparent tw-block tw-bg-gradient-to-r tw-from-cyan-500 tw-to-green-300 tw-bg-clip-text ">
                Browse
              </span>
              <span className="tw-block">Through</span>
              <span className="tw-block"> My Journey</span>
              <span className="tw-block"> And Experiences</span>
            </h1>

            <div className="tw-flex tw-gap-4 tw-items-center">
              <Link to="/package">
                <button
                  type="button"
                  className="button tw-px-8 tw-py-3 tw-bg-[#0891b2] tw-rounded-md tw-text-white tw-text-base tw-font-bold"
                >
                  Book a tour
                </button>
              </Link>
              <div className="link tw-text-base tw-font-bold hover:tw-underline">
                <Link to="/blogs">Watch My Stories</Link>
              </div>
            </div>
          </div>
          <div className="tw-transition-all tw-duration-500 max-md:tw-mt-4 ">
            <img src="landingBg.png" alt="bg" layout="fill" width={650} />
          </div>
        </section>
      </main>
      <section className="tw-relative tw-z-[500] max-md:-tw-mt-[100vh] -tw-mt-[100vh]  tw-bg-[#073549]">
        <About />
      </section>
    </>
  );
};

export default Home;
