/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import db from "../../../firebaseConfig";
import TrackingRouteStepBar from "./Stepbar";
import Package from "./../index";
import ItineraryComponent from "../Itinerary";

const PackageDetail = () => {
  const { id } = useParams();
  const [packageDetails, setPackageDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("overview");
  const [selectedPackage, setSelectedPackage] = useState([]);

  useEffect(() => {
    const fetchTravelPackages = async () => {
      try {
        const packageRef = collection(db, "travelpackages");
        const packageSnapshot = await getDocs(packageRef);

        const filteredPackages = [];

        for (const packageDoc of packageSnapshot.docs) {
          const packageData = { id: packageDoc.id, ...packageDoc.data() };
          const innerCollectionRef = collection(packageDoc.ref, "subPackages");
          const innerCollectionSnapshot = await getDocs(innerCollectionRef);
          const subPackages = innerCollectionSnapshot.docs.map((innerDoc) => ({
            id: innerDoc.id,
            ...innerDoc.data(),
          }));

          packageData.subPackages = subPackages;

          const filteredSubPackages = subPackages.filter(
            (subPackage) => subPackage.title === id
          );
          if (filteredSubPackages.length > 0) {
            packageData.subPackages = filteredSubPackages;
            filteredPackages.push(packageData);
          }
        }

        setPackageDetails(filteredPackages);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching travel packages:", error);
      }
    };

    fetchTravelPackages();
  }, [id]);

  const switchToTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div
      id="package"
      className="tw-bg-[#d3ecf7] tw-relative tw-w-full tw-min-h-screen"
    >
      {loading ? (
        <div className="bg-[#d3ecf7] tw-min-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center">
          <h2 className="tw-text-xl tw-font-bold tw-text-gray-800">
            Loading...
          </h2>
          <img
            src="/loader.svg"
            alt="loader"
            width={250}
            className="tw-mx-auto"
          />
        </div>
      ) : (
        <>
          <h1 className="tw-text-center title-color tw-text-4xl tw-font-semibold tw-tracking-wide tw-uppercase">
            Package Detail
          </h1>
          <div className="tw-mt-3">
            <div className="tw-flex tw-justify-center md:tw-justify-start md:tw-px-20">
              <div className="tw-flex tw-gap-10 tw-text-xl md:tw-text-3xl tw-mb-2 md:tw-w-full">
                <button
                  onClick={() => switchToTab("overview")}
                  className={`tw-font-semibold tw-px-2 tw-py-1.5 tw-rounded-sm ${
                    activeTab === "overview"
                      ? "tw-text-[#0891b2] tw-bg-[#a5e4f6]"
                      : ""
                  }`}
                >
                  Overview
                </button>
                <button
                  onClick={() => switchToTab("itinerary")}
                  className={`tw-font-semibold tw-px-2 tw-py-1.5 tw-rounded-sm ${
                    activeTab === "itinerary"
                      ? "tw-text-[#0891b2] tw-bg-[#a5e4f6] "
                      : ""
                  }`}
                >
                  Itinerary
                </button>
              </div>
            </div>
            <div className="tw-flex md:tw-mt-5 tw-flex-wrap tw-px-[4%] tw-gap-10 tw-pb-[2.5%] tw-flex-col tw-items-center tw-justify-center ">
              {packageDetails.map((travelPackage) =>
                travelPackage.subPackages.map((segment) => (
                  <div
                    key={segment.id}
                    className="tw-flex tw-flex-col tw-gap-5 tw-w-[86%]"
                  >
                    {activeTab === "overview" && (
                      <div>
                        <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-gap-4 tw-justify-between tw-items-center">
                          <div className="tw-flex tw-flex-col tw-gap-2">
                            <h1 className="tw-text-2xl md:tw-text-3xl tw-capitalize tw-font-bold">
                              {segment.title}
                            </h1>
                            <h1 className=" tw-capitalize tw-font-bold">
                              Duartion: {segment.days} Days
                            </h1>
                            <Link
                              to={`/contact?package=${travelPackage.title} (${segment.title})`}
                              onClick={() => setSelectedPackage(segment.title)}
                            >
                              <button className="tw-bg-[#0891b2] tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-[#0d6379]">
                                Book Now
                              </button>
                            </Link>
                          </div>
                          <img
                            src={segment.image}
                            alt={segment.image}
                            width={650}
                          />
                        </div>
                        <p className="tw-text-justify tw-mt-5">
                          {segment.desc}
                        </p>
                      </div>
                    )}
                    {activeTab === "itinerary" && (
                      <div className="tw-flex tw-flex-col tw-text-center tw-gap-5">
                        <ItineraryComponent htmlContent={segment.itinerary} />
                        <Link
                          to={`/contact?package=${travelPackage.title} (${segment.title})`}
                          onClick={() => setSelectedPackage(segment.title)}
                        >
                          <button className="tw-bg-[#0891b2] tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-[#0d6379]">
                            Book Now
                          </button>
                        </Link>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PackageDetail;
