
import firebase from 'firebase/compat/app';
import { getFirestore } from 'firebase/firestore';

  const firebaseConfig = {
    apiKey: "AIzaSyAfxEIjEMBe7g5M-jGksHAsBFM8q4Bhfkw",
    authDomain: "adventure-web-49770.firebaseapp.com",
    projectId: "adventure-web-49770",
    storageBucket: "adventure-web-49770.appspot.com",
    messagingSenderId: "707247655665",
    appId: "1:707247655665:web:370b064f61a4086741f750"
  };

firebase.initializeApp(firebaseConfig);
const db = getFirestore();

export default db;

