export const extractContent = (content) => {
  if (!content || !Array.isArray(content)) {
    return null;
  }

  return content.map((block, index) => {
    switch (block._type) {
      case "block":
        return (
          <p key={index} className={block.style}>
            {block.children.map((child, childIndex) => {
              switch (child._type) {
                case "span":
                  return (
                    <span
                      key={childIndex}
                      className={child.marks && child.marks.join(" ")}
                    >
                      {child.text}
                    </span>
                  );
                case "image":
                  if (child.asset && child.asset._ref) {
                    const image = child.asset._ref;
                    const imageUrl = `https://cdn.sanity.io/images/production/${image}`;
                    return (
                      <img
                        key={childIndex}
                        src={imageUrl}
                        alt={child.alt}
                        className="tw-rounded-lg tw-mb-8"
                      />
                    );
                  }
                  return null;
                default:
                  return null;
              }
            })}
          </p>
        );
      default:
        return null;
    }
  });
};
