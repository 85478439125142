import { createClient } from "@sanity/client";

const client = createClient({
  projectId: "7hlmoz3o",
  dataset: "production",
  useCdn: false,
  apiVersion: "2021-08-31",
  // token: "",
});

export default client;