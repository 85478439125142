import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const navigation = [
  { name: "Home", href: "/" },
  { name: "About", href: "/about" },
  { name: "Packages", href: "/package" },
  { name: "Gallery", href: "/gallery" },
  { name: "Blogs", href: "/blogs" },
  { name: "Contact", href: "/contact" },
];

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const { pathname } = useLocation();

  const handleActiveTab = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    setActiveTab(pathname);
  }, [pathname]);

  return (
    <header className="tw-inset-x-0 tw-top-0 tw-z-50 tw-sticky  tw-bg-[#d3ecf7]">
      <nav
        className="tw-flex tw-items-center tw-justify-between tw-p-5 lg:tw-px-20"
        aria-label="Global"
      >
        <div className="tw-flex lg:tw-flex-1">
          <a href="/" className="tw--m-1.5 tw-p-1.5">
            <img
              className="max-md:tw-w-[20rem] tw-w-[24rem] tw-h-auto"
              src="logo.svg"
              alt=""
            />
          </a>
        </div>
        <div className="tw-flex lg:tw-hidden">
          <button
            type="button"
            className="tw--m-2.5 tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-p-2.5 tw-text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="tw-sr-only">Open main menu</span>
            <Bars3Icon className="tw-h-6 tw-w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="tw-hidden lg:tw-flex lg:tw-gap-x-12">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className={`${
                activeTab === item.href
                  ? "tw-bg-[#08648c] tw-text-white tw-opacity-90 tw-rounded-sm hover:tw-no-underline "
                  : ""
              } tw-text-xl tw-font-semibold tw-leading-6 tw-text-[#08648c] tw-px-2 tw-py-1 tw-underline-offset-4 hover:tw-underline`}
              onClick={() => handleActiveTab(item.href)}
            >
              {item.name}
            </a>
          ))}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:tw-hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="tw-fixed tw-inset-0 tw-z-50" />
        <Dialog.Panel className="tw-fixed tw-inset-y-0 tw-right-0 tw-z-50 tw-w-full tw-overflow-y-auto tw-bg-[#d3ecf7] tw-px-6 tw-py-6 sm:tw-max-w-sm sm:tw-ring-1 sm:ring-[#08648c]/10">
          <div className="tw-flex tw-items-center tw-justify-between">
            <a href="/" className="tw--m-1.5 tw-p-1.5">
              <img className="tw-h-12 tw-w-auto" src="logo.svg" alt="" />
            </a>
            <button
              type="button"
              className="tw--m-2.5 tw-rounded-md tw-p-2.5 tw-text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="tw-sr-only">Close menu</span>
              <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="tw-mt-6 tw-flow-root">
            <div className="tw--my-6 tw-divide-y tw-divide-gray-500/10">
              <div className="tw-space-y-2 tw-py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="tw--mx-3 tw-block tw-rounded-lg tw-px-3 tw-py-2 tw-text-xl tw-font-semibold tw-leading-7 tw-text-[#08648c] hover:tw-bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Header;
