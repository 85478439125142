import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import db from "./../../firebaseConfig";
import ImageSlider from "../../components/ImageSlider";

const Gallery = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const galleryRef = collection(db, "gallery");
        const gallerySnapshot = await getDocs(galleryRef);
        const images = gallerySnapshot.docs.map((doc) => doc.data());
        setGalleryImages(images);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching gallery images:", error);
      }
    };

    fetchGalleryImages();
  }, []);

  const formatDateTime = (seconds, nanoseconds) => {
    const milliseconds = seconds * 1000 + nanoseconds / 1e6;
    const date = new Date(milliseconds);
    return date.toLocaleString();
  };

  return (
    <div
      id="gallery"
      className="tw-bg-[#d3ecf7] tw-relative tw-min-h-screen tw-w-full"
    >
      {loading ? (
        <div className="tw-bg-[#d3ecf7] tw-min-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center">
          <h2 className="tw-text-xl tw-font-bold tw-text-gray-800">
            Loading...
          </h2>
          <img
            src="/loader.svg"
            alt="loader"
            width={250}
            className="tw-mx-auto"
          />
        </div>
      ) : (
        <div className=" tw-py-8">
          <h1 className="tw-text-center title-color tw-text-2xl sm:tw-text-4xl tw-font-semibold tw-tracking-wide xl:tw-text-5xl  tw-uppercase ">
            Our collection
          </h1>
          {galleryImages.map((imagestore, index) => (
            <div key={index}>
              <div
                key={index}
                className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-gap-[1%] tw-justify-center"
              >
                <div className="tw-block md:tw-hidden md:tw-w-[16%] tw-flex-col tw-gap-4">
                  <div className="tw-mt-4 tw-text-xl tw-font-semibold tw-text-gray-800">
                    {imagestore.detail}
                  </div>
                  <div className="tw-text-gray-600">
                    {formatDateTime(
                      imagestore.capturedate.seconds,
                      imagestore.capturedate.nanoseconds
                    )}
                  </div>
                </div>
                <div className="md:tw-w-full tw-m-[2%] ">
                  <ImageSlider images={imagestore.images} />
                </div>
                <div className="tw-hidden md:tw-block md:tw-w-[16%] tw-flex-col tw-gap-4">
                  <div className="tw-mt-4 tw-text-xl tw-font-semibold tw-text-gray-800">
                    {imagestore.detail}
                  </div>
                  <div className="tw-text-gray-600">
                    {formatDateTime(
                      imagestore.capturedate.seconds,
                      imagestore.capturedate.nanoseconds
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Gallery;
